import { createContext, ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'

import { Alert, Snackbar, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

export const NotificationContext = createContext<{
    showSuccessNotification: (_text: string) => void
    showErrorNotification: (_text: string) => void
}>({
    showSuccessNotification: () => {},
    showErrorNotification: () => {}
})

type Props = {
    children: ReactNode
}

const messageTypes = {
    SUCCESS: 'success',
    ERROR: 'error'
} as const

const initialMessageState = {
    type: messageTypes.SUCCESS,
    text: ''
} as const

type PortalProps = {
    children: ReactNode
}

const ReactPortal = ({ children }: PortalProps) => {
    const container = document.getElementById('portal')

    if (container) {
        return createPortal(children, container)
    }

    return null
}

export const NotificationProvider = ({ children }: Props) => {
    const [message, setMessage] = useState<{
        type: 'success' | 'error'
        text: string
    }>(initialMessageState)

    const handleClose = () => setMessage(initialMessageState)

    const showSuccessNotification = (text: string) => {
        setMessage({
            type: messageTypes.SUCCESS,
            text
        })
    }

    const showErrorNotification = (text: string) => {
        setMessage({
            type: messageTypes.ERROR,
            text
        })
    }

    return (
        <NotificationContext.Provider
            value={{
                showSuccessNotification,
                showErrorNotification
            }}
        >
            <ReactPortal>
                <Snackbar
                    open={Boolean(message.text)}
                    autoHideDuration={4000}
                    style={{ zIndex: 1301, position: 'fixed' }}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        style={{ zIndex: 2000 }}
                        severity={message.type}
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        {message.text}
                    </Alert>
                </Snackbar>
            </ReactPortal>
            {children}
        </NotificationContext.Provider>
    )
}
