import { lazy } from 'react'

import { paths } from './paths'

const PagePlaybook = lazy(() => import('pages/playbook/PagePlaybook'))
const PagePlaylist = lazy(() => import('pages/playlist/PagePlaylist'))
const PageSsoLogin = lazy(() => import('pages/PageSsoLogin'))

export const routes = [
    { path: paths.playbookDetails, component: <PagePlaybook /> },
    { path: paths.playlistDetails, component: <PagePlaylist /> },
    { path: paths.ssoLogin, component: <PageSsoLogin /> }
]

export type RoutesType = typeof routes
